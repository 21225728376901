// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donation-application-js": () => import("./../../../src/pages/donation-application.js" /* webpackChunkName: "component---src-pages-donation-application-js" */),
  "component---src-pages-explore-our-impact-js": () => import("./../../../src/pages/explore-our-impact.js" /* webpackChunkName: "component---src-pages-explore-our-impact-js" */),
  "component---src-pages-give-js": () => import("./../../../src/pages/give.js" /* webpackChunkName: "component---src-pages-give-js" */),
  "component---src-pages-grant-program-js": () => import("./../../../src/pages/grant-program.js" /* webpackChunkName: "component---src-pages-grant-program-js" */),
  "component---src-pages-grant-recipients-js": () => import("./../../../src/pages/grant-recipients.js" /* webpackChunkName: "component---src-pages-grant-recipients-js" */),
  "component---src-pages-home-2024-js": () => import("./../../../src/pages/home-2024.js" /* webpackChunkName: "component---src-pages-home-2024-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recipients-js": () => import("./../../../src/pages/recipients.js" /* webpackChunkName: "component---src-pages-recipients-js" */),
  "component---src-pages-scholarship-terms-js": () => import("./../../../src/pages/scholarship-terms.js" /* webpackChunkName: "component---src-pages-scholarship-terms-js" */),
  "component---src-pages-scholarships-application-js": () => import("./../../../src/pages/scholarships-application.js" /* webpackChunkName: "component---src-pages-scholarships-application-js" */),
  "component---src-pages-scholarships-js": () => import("./../../../src/pages/scholarships.js" /* webpackChunkName: "component---src-pages-scholarships-js" */)
}

